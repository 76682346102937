import service from '@/utils/request';

// @Tags SysGroup
// @Summary 创建SysGroup
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroup true "创建SysGroup"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysGroup/createSysGroup [post]
export var createSysGroup = function createSysGroup(data) {
  return service({
    url: "/sysGroup/createSysGroup",
    method: 'post',
    data: data
  });
};
export var addGroupUser = function addGroupUser(data) {
  return service({
    url: "/sysGroup/addGroupUser",
    method: 'post',
    data: data
  });
};
export var addGroupReport = function addGroupReport(data) {
  return service({
    url: "/sysGroup/addGroupReport",
    method: 'post',
    data: data
  });
};

// @Tags SysGroup
// @Summary 删除SysGroup
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroup true "删除SysGroup"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysGroup/deleteSysGroup [delete]
export var deleteSysGroup = function deleteSysGroup(data) {
  return service({
    url: "/sysGroup/deleteSysGroup",
    method: 'delete',
    data: data
  });
};

// @Tags SysGroup
// @Summary 删除SysGroup
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SysGroup"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysGroup/deleteSysGroup [delete]
export var deleteSysGroupByIds = function deleteSysGroupByIds(data) {
  return service({
    url: "/sysGroup/deleteSysGroupByIds",
    method: 'delete',
    data: data
  });
};

// @Tags SysGroup
// @Summary 更新SysGroup
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroup true "更新SysGroup"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysGroup/updateSysGroup [put]
export var updateSysGroup = function updateSysGroup(data) {
  return service({
    url: "/sysGroup/updateSysGroup",
    method: 'put',
    data: data
  });
};

// @Tags SysGroup
// @Summary 用id查询SysGroup
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroup true "用id查询SysGroup"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysGroup/findSysGroup [get]
export var findSysGroup = function findSysGroup(params) {
  return service({
    url: "/sysGroup/findSysGroup",
    method: 'get',
    params: params
  });
};

// @Tags SysGroup
// @Summary 分页获取SysGroup列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysGroup列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysGroup/getSysGroupList [get]
export var getSysGroupList = function getSysGroupList(params) {
  return service({
    url: "/sysGroup/getSysGroupList",
    method: 'get',
    params: params
  });
};
export var addGroupQE = function addGroupQE(data) {
  return service({
    url: "/sysGroup/addGroupQE",
    method: 'post',
    data: data
  });
};